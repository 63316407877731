<template>
  <b-row>
    <b-col cols="12">
    
      <b-card>
      <validation-observer ref="simpleForm">
      <form >
      <b-tabs lazy vertical nav-wrapper-class="nav-hidden" align="left" >
        <b-tab
    
      >
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>{{$t('personal_info')}}</span>
            </template>


            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Global.english_name')"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="english|required"
                  >
             
                    <b-form-input
                      id="name-input"
                      v-model="form.name"
                      :state="errors.length > 0 ? false : null"
                      @keypress="
                        sanitizeEnglish($event);
                        sanitizeNumber($event);
                      "
                    
                    />
                    <ValidationErrors
                     
                      :frontend-errors="errors"
                      :backend-errors="
                        getBackendFieldError(errorsdata, 'name')
                      "
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
          
              <b-col md="6">
                <b-form-group :label="$t('email')" label-for="vi-email">
                  <validation-provider
                    #default="{ errors }"
                    rules="email|required"
                    name="email"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="vi-email"
                      type="email"
                      v-model="form.email"
                      placeholder="john.doe@email.com"
                    />
                    <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group style="font-size: 12px" label="Role">
                   <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="role"
                  >
                  <v-select
                    :filter="fuseSearch"
                    v-model="form.roles"
                    :options="roless"
                    multiple
                    :reduce="(val) => parseInt(val.id)"
                    input-id="program-input"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>

                    <template #selected-option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options>
                      {{ $t("noMatching") }}
                    </template>
                  </v-select>
                     <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group style="font-size: 12px" label="Branch">
                   <validation-provider
                    #default="{ errors }"
                  
                    name="branch"
                  >
                  <v-select
                    :filter="fuseSearch"
                    v-model="form.branch"
                    :options="branches"
                    multiple
                    :reduce="(val) => val.id"
                    input-id="program-input"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>

                    <template #selected-option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options>
                      {{ $t("noMatching") }}
                    </template>
                  </v-select>
                     <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col md="6">
                <b-form-group style="font-size: 12px" label="Type">
                   <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="type"
                  >
                  <v-select
                    :filter="fuseSearch"
                    v-model="form.type"
                    :options="types"
                    
                    :reduce="(val) => val.type"
                    input-id="program-input"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>

                    <template #selected-option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options>
                      {{ $t("noMatching") }}
                    </template>
                  </v-select>
                     <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

         </b-tab>

         <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>Change Password</span>
          </template>
          <b-col md="6">
            <b-form-group
              label="New Password"
              label-for="name-input"
            >
            <!--  -->
<!--  -->
              <validation-provider
                #default="{ errors }"
                name="name"
               invalid-feedback="Password is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="form.password"
                  @keypress="
                        sanitizeEnglish($event);
                       
                      "
                />
                <ValidationErrors
                  default-message
                  :frontend-errors="errors"
                  :backend-errors="
                    getBackendFieldError(errorsdata, 'password')
                  "
                   :state="errors.length > 0 ? false : null"
                /> 
             </validation-provider>
            </b-form-group>
          </b-col>

         </b-tab>
      </b-tabs>
      </form>
           </validation-observer>
    </b-card>
    <b-row class="mb-5 ">
      <div class="ml-5  w-100">
      
          <b-button class="btn btn-info mr-2" @click="save">
            Save
          </b-button>
        
       

        <router-link :to="`/users`">
          <b-button class="mr-5"> Cancel </b-button>
        </router-link>

      </div>
    </b-row>
    </b-col>
  </b-row>
</template>

<script>
import show from "@/views/cases/components/show";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Multiselect from "vue-multiselect";
import { required, email, digits, length } from "@validations";
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BOverlay,
  BCard,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTag,
  BFormTags,
  BFormTextarea,
} from "bootstrap-vue";
import { localize, ValidationObserver, ValidationProvider } from "vee-validate";
import ValidationErrors from "@/views/components/common/ValidationErrors";
import { mapActions, mapGetters } from "vuex";


export default {
  components: {
    FormWizard,
    BOverlay,
    TabContent,
    BRow,
    BTab,
  BTabs,
    BFormTag,
    BFormTags,
    BFormDatepicker,
    BCol,
    BFormGroup,
    BCard,
    BFormInput,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    localize,
    Multiselect,
    ValidationErrors,
  },
  computed: {
    ...mapGetters({
      lookups: "app/lookups",
      user: "users/item",
      roless: "roles/roles",
      load: "users/load",
      userRoles: "roles/userRoles",
    }),

    id() {
      return this.$route.params && this.$route.params.id
        ? this.$route.params.id
        : null;
    },
    types() {
      return [
        { type: 1, name: "Auditor" },
        { type: 5, name: "Other" },
 
      ];
    },
    genders() {
      return [
        { id: 1, label: this.$t("Global.male") },
        { id: 2, label: this.$t("Global.female") },
      ];
    },
    genders2() {
      return [1, 2];
    },
  },
  props: {
    type: null,
  },
  data() {
    return {
      branches: [],
      //  roless:[],
      user_roles: [],
      errors_data: {},
      errorsdata: {},
      academic_info: false,
      address: false,
      contact: false,
      form: {},
      query: {
        ranks: true,
        faculties: true,
        employee_types: true,
        faculty_departments: true,
      },
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions({
      saveUser: "users/put",
    }),
    changeTab(pre, next) {
      if (next == 1) {
        this.academic_info = true;
      } else {
        this.academic_info = false;
      }
      if ((this.type == 1 && next == 2) || (this.type != 1 && next == 1)) {
        this.address = true;
      } else {
        this.address = false;
      }
      if ((this.type == 1 && next == 3) || (this.type != 1 && next == 2)) {
        this.contact = true;
      } else {
        this.contact = false;
      }
    },

    validateAsync: function (index) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$refs.simpleForm.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        }, 1000);
      });
    },

    init() {
      // this.form = { type: 1 };
      if (this.id) {
        this.$store.dispatch("users/get", this.id).then((_) => {
          if (this.user) {
            if (this.user.instructor_data) {
              this.form = { ...this.user, ...this.user.instructor_data };
            } else if (this.user.employee_data) {
              this.form = { ...this.user, ...this.user.employee_data };
            } else {
              this.form = this.user;
            }
            // this.form.name_local = this.user.name_local;
            // this.form.name = this.user.name;
            this.form.type = this.type;
            for (const key of Object.entries(this.user.roles)) {
              this.user_roles.push([
                {
                  name: key,
                },
              ]);
            }
            console.log(this.user_roles);
          }
        });
      }
      let query = {};
      this.$store.dispatch("branches/branches", { query }).then((res) => {
        this.branches = res;
      });

      this.$store.dispatch("roles/getRoles").then((res) => {
        //  this.roless=res;
        //  console.log(this.roless);
      });

      //       this.$store.dispatch('roles/getAuthUserRolesPermissions',this.$route.params.id ).then( res => {
      //         console.log(res);
      //         let x = res.data.roles;

      // this.form.roles ={ "name": Object.values(x)[0] , "id": Object.keys(x)[0]}
      // // console.log(this.form.roles);

      //       })

      // this.getLookups(this.query).then(_ => {

      // });
    },

    back() {
      this.$router.push({
        name: "users",
      });
    },
    save() {
   
      this.$refs.simpleForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("users/put", { id: this.id, query: this.form })
            .then((res) => {

 if (res == 'error')
 {
      this.$swal({
                icon: "error",
                title: 'This Email does already exist',
                showConfirmButton: false,
                timer: 2000,
              });

              // this.$router.push({
              //   name: "users",
              // });
 }
 else {

              this.$swal({
                icon: "success",
                title: this.$t("Global.Saved"),
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({
                name: "users",
              });
 }





            
            
            })
            .catch((error) => {
              this.errorsdata = this.handleBackendError(
                error.response.data.errors
              );

              console.log(this.errors_data);
            });
       }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.wizard-btn,
.wizard-icon-container {
  background-color: #054978 !important;
  border-color: #054978 !important;
}

.stepTitle.active {
  color: #054978 !important;
}
</style>

<!-- <b-col md="6">
                  <b-form-group
                    :label="$t('Global.arabic_name')"
                    label-for="nameAr-input"
                    invalid-feedback="Name is required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name_local"
                      rules="required"
                    >
                      <b-form-input
                        id="nameAr-input"
                        v-model="form.name_local"
                        class="right_left"
                        :state="errors.length > 0 ? false : null"
                      />
                      <ValidationErrors
                        default-message
                        :frontend-errors="errors"
                        :backend-errors="
                          getBackendFieldError(errorsdata, 'name_local')
                        "
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
<!-- <b-col md="6">
                <b-form-group :label="$t('Global.code')" label-for="code-input">
                  <validation-provider #default="{ errors }" name="code" rules="code|required">
                    <b-form-input id="code-input" v-model="form.code" :state="errors.length > 0 ? false : null" />
                    <ValidationErrors default-message :frontend-errors="errors" :backend-errors="getBackendFieldError(errorsdata, 'code')" />
                  </validation-provider>
                </b-form-group>
              </b-col> -->

<!-- <b-col md="6">
                <b-form-group :label="$t('Global.personal_email')" label-for="vi-personal-email">
                  <validation-provider #default="{ errors }" rules="required|email" name="email">
                    <b-form-input :state="errors.length > 0 ? false : null" id="vi-personal-email" type="email" v-model="form.personal_email" :placeholder="$t('Global.personal_email')" />
                    <ValidationErrors default-message :frontend-errors="errors" />
                  </validation-provider>
                </b-form-group>
              </b-col> -->

<!-- <b-col md="6">
                  <b-form-group
                    :label="$t('Global.mobile')"
                    label-for="vi-mobile"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required|mobile"
                      name="department_id"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="vi-mobile"
                        v-model="form.mobile"
                        :placeholder="$t('Global.mobile')"
                      />
                      <ValidationErrors
                        default-message
                        :frontend-errors="errors"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('Global.phone')"
                    label-for="vi-phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="phone"
                    >
                      <b-form-input
                        @keypress="writeNumber($event)"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                        id="vi-phone"
                        v-model="form.phone"
                        :placeholder="$t('Global.phone')"
                      />
                      <ValidationErrors
                        default-message
                        :frontend-errors="errors"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="type == 2">
                  <b-form-group
                    :label="$t('Global.internal_phone')"
                    label-for="vi-internal_phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="internal_phone"
                    >
                      <b-form-input
                        @keypress="writeNumber($event)"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                        id="vi-internal_phone"
                        v-model="form.internal_phone"
                        :placeholder="$t('Global.internal_phone')"
                      />
                      <ValidationErrors
                        default-message
                        :frontend-errors="errors"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
