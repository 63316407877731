<template>
  <b-row>
    <b-col cols="12" xl="12" lg="12" md="12">
     <!---CARD-----> 
    </b-col>
    <b-col cols="12" xl="12" lg="12" md="12">
      <b-card>
        <b-tabs v-if="isVertical" lazy vertical nav-wrapper-class="nav-hidden" align="left" >
          <slot name="add_tab"></slot>
        </b-tabs>
         <b-tabs v-else lazy horizontal nav-wrapper-class="nav-hidden" align="left" v-model="tabIndex" @change="$emit('changeTab' , tabIndex)"  color="#054978">
          <slot name="add_tab" ></slot>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BTabs,BLink, BTab, BCardText, BCard, BCol, BRow, BAvatar, BOverlay } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import List from '@/views/components/info/list';

export default {
  components: {
    List,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BCard,
    BAvatar,
    BCol,
    BRow,
    BOverlay,
  },
  props: {
    item: null,
    load: false,
    type: 0,
    isVertical: true,
  },

  data() {
    return {
      tabIndex:null,
      file: null,
      loading: false,
    };
  },

  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },

  methods: {
    restore(userId) {
      this.$swal({
        title: `${this.$t('Global.deleteTitle')}`,
        text: 'You Will Activate User!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: `${this.$t('Global.cancel')}`,
        confirmButtonText: 'Yes, Activate!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('users/restore', userId).then(_ => {
            this.$emit('refresh');
            this.$swal({
              icon: 'success',
              text: this.$t('Global.successMessage'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },
    remove(userId) {
      this.$swal({
        title: `${this.$t('Global.deleteTitle')}`,
        text: `${this.$t('Global.deleteText')}`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: `${this.$t('Global.cancel')}`,
        confirmButtonText: `${this.$t('Global.deleteBtn')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('users/remove', userId).then(_ => {
            this.$emit('refresh');
            this.$swal({
              icon: 'success',
              text: this.$t('Global.deletedUser'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },
    open_role(id) {
      window.open(this.$router.resolve({ name: 'user-role', params: { id: id } }).href, '_blank');
    },
    uploadPhoto() {
      let data = new FormData();
      data.append('file', this.file);
      data.append('short_name', 'PERSONAL_PHOTO');
      let payload = {
        id: this.item ? this.item.archive_id : null,
        photo_archive_id: this.item && this.item.photo_archive_id ? this.item.photo_archive_id : null,
        query: data,
      };
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!allowedExtensions.exec(this.file.name)) {
        this.errorToast(this.$t('Global.extension_error'))
      } else {
        this.loading = true;
        this.$store.dispatch('users/uploadPhoto', payload).then(_ => {
          this.$store.dispatch('users/get', this.id);
          this.loading = false;
        });
      }
    },
    changePhoto(e) {
      this.file = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        // this.status = true;
        // this.previewImage = e.target.result;
        this.uploadPhoto();
      };
    },
  },
};
</script>

<style lang="scss">
.top_left {
  top: -15px;
  left: -15px;
}

</style>
