var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('validation-observer',{ref:"simpleForm"},[_c('form',[_c('b-tabs',{attrs:{"lazy":"","vertical":"","nav-wrapper-class":"nav-hidden","align":"left"}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('personal_info')))])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.english_name'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":"name","rules":"english|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","state":errors.length > 0 ? false : null},on:{"keypress":function($event){_vm.sanitizeEnglish($event);
                        _vm.sanitizeNumber($event);}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors,"backend-errors":_vm.getBackendFieldError(_vm.errorsdata, 'name')}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"vi-email"}},[_c('validation-provider',{attrs:{"rules":"email|required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"vi-email","type":"email","placeholder":"john.doe@email.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticStyle:{"font-size":"12px"},attrs:{"label":"Role"}},[_c('validation-provider',{attrs:{"rules":"required","name":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"filter":_vm.fuseSearch,"options":_vm.roless,"multiple":"","reduce":function (val) { return parseInt(val.id); },"input-id":"program-input"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(ref){
                        var name = ref.name;
return [_c('strong',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.roles),callback:function ($$v) {_vm.$set(_vm.form, "roles", $$v)},expression:"form.roles"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticStyle:{"font-size":"12px"},attrs:{"label":"Branch"}},[_c('validation-provider',{attrs:{"name":"branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"filter":_vm.fuseSearch,"options":_vm.branches,"multiple":"","reduce":function (val) { return val.id; },"input-id":"program-input"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(ref){
                        var name = ref.name;
return [_c('strong',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.branch),callback:function ($$v) {_vm.$set(_vm.form, "branch", $$v)},expression:"form.branch"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticStyle:{"font-size":"12px"},attrs:{"label":"Type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"filter":_vm.fuseSearch,"options":_vm.types,"reduce":function (val) { return val.type; },"input-id":"program-input"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(ref){
                        var name = ref.name;
return [_c('strong',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}])})],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_c('span',[_vm._v("Change Password")])]},proxy:true}])},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"name-input"}},[_c('validation-provider',{attrs:{"name":"name","invalid-feedback":"Password is required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input"},on:{"keypress":function($event){return _vm.sanitizeEnglish($event);}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('ValidationErrors',{attrs:{"default-message":"","frontend-errors":errors,"backend-errors":_vm.getBackendFieldError(_vm.errorsdata, 'password'),"state":errors.length > 0 ? false : null}})]}}])})],1)],1)],1)],1)],1)])],1),_c('b-row',{staticClass:"mb-5 "},[_c('div',{staticClass:"ml-5  w-100"},[_c('b-button',{staticClass:"btn btn-info mr-2",on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('router-link',{attrs:{"to":"/users"}},[_c('b-button',{staticClass:"mr-5"},[_vm._v(" Cancel ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }